import * as React from "react"
import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import Section from "../../components/ui/base/Section"
import TourCard from "../../components/ui/base/TourCard"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import { createMarkup, getProductsList } from "../../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { BASE_URL, COLLECTIONS_URL, PHOTO_TOURS_URL } from "../../util/constants"
import { useTranslation } from "react-i18next"
import Faq from "../../components/ui/base/Faq"
import { FAQJsonLd } from "gatsby-plugin-next-seo"


const CollectionPage = ({ data }) => {

  const { i18n: l  } = useTranslation();

  const collection = data.strapiCollection;
  const collectionLink = `${BASE_URL[l.language]}/${PHOTO_TOURS_URL[l.language]}/${COLLECTIONS_URL[l.language]}/${collection.slug}`;
  const tours = data.allStrapiTour.edges;

  useEffect(() => {
    if (typeof window !== 'undefined' && data.allStrapiTour){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item_list",
        'ecommerce': {
          'currencyCode': 'EUR',
          'items': [getProductsList(tours, collection.name )]
    }
    });
    }
  }, []);

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang : l.language }}
        title={collection.seo?.metaTitle}
        description={collection?.seo?.metaDescription}
        canonical={collectionLink}
        language={l.language}
        openGraph={{
          type: "website",
          url: collectionLink,
          title: collection?.seo?.metaTitle,
          description: collection?.seo?.metaDescription,
          images: [
            {
              url: collection?.seo?.shareImage.formats.medium.url,
              width: collection?.seo?.shareImage.formats.medium.width,
              height: collection?.seo?.shareImage.formats.medium.height,
              alt: collection?.seo?.metaTitle,
            },
          ],
          site_name: 'WeShoot',
        }}

      />

      <Header
        img={data.strapiCollection.image.localFile.childImageSharp.gatsbyImageData}
        theme="dark"
        size={"compact"}
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  {collection.name}
                </Typography>
                <Breadcrumbs elements={[
                  {
                    name: "WeShoot",
                    path: "/",
                  },
                  {
                    name: "Viaggi Fotografici",
                    path: "/viaggi-fotografici/",
                  },
                  {
                    name: "Collezioni",
                    path: "/viaggi-fotografici/collezioni/",
                  },{
                    name: collection.name,
                    path: `/viaggi-fotografici/collezioni/${collection.slug}`,
                  },
                ]} />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section variant={"white"} title={collection.excerpt} >

        <Row>
          <Col xs={12}>
            <div dangerouslySetInnerHTML={createMarkup(collection.description)}/>
          </Col>
        </Row>
        <Row className="mt-5">
          {tours.map((tour,i) => {
            return (
              <Col lg={4} md={6} className="zoomIn mb-2" key={`tour-${i}`}>
                <TourCard tour={tour.node}/>
              </Col>
            )
          })
          }
        </Row>
      </Section>

      {collection.faqs && collection.faqs.length > 0 && <Section variant={"grey"} title={`Domande piú frequenti su ${collection.name}`} >
        {collection.faqs?.map(q => {
          return (
            <Faq faq={q}/>
          )
        })}
        <FAQJsonLd
          questions={collection.faqs}
        />
      </Section>}
    </>
  )
}

export default CollectionPage

export const query = graphql`
  query CollectionQuery($slug: String!) {
  strapiCollection(slug: {eq: $slug}) {
  excerpt
  id
  name
  slug
  description
  faqs {
      answer
      id
      question
  }
  seo {
      metaDescription
      metaTitle
      shareImage {
        formats {
          medium {
            url
            width
            height
          }
        }
      }
    }
  image {
    localFile {
          ...ImageFragment
      }
  }
}
  allStrapiTour(
      filter: {collections: { elemMatch: {slug: {eq: $slug}}}}
) {
  edges {
    node {
      id
       image {
          url
          localFile {
              ...ImageFragment
          }
      }
      price
      slug
      states {
        slug
      }
      places {
        slug
      }
      sessions {
        end
        start
      }
         sessions {
        end
        id
        sessionId
        start
        status
        maxPax
        balance
        price
        deposit
        currency
        users {
          id
          firstName
          instagram
          profilePicture {
            localFile {
              ...ImageFragment
            }
          }
        }
      }
      excerpt
      experienceLevel
      title
    }
  }
}
}
`


